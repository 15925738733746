/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*👇 this is...wut?*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    p: "p",
    h3: "h3",
    pre: "pre",
    code: "code",
    h2: "h2",
    a: "a",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "A common gotcha is setting cronjobs as user vs root"), "\n"), "\n", React.createElement(_components.h3, null, "Shell Script Cheatsheet for Macos"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "crontab -e # edit cron jobs\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  })), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "crontab -l # list all cron jobs\n")), "\n", React.createElement(_components.h2, null, "Tools and Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://crontab.guru/"
  }, "cronttab.guru")), "\n", React.createElement(_components.h3, null, "Debugging CronJobs"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Dry run the script we are trying to run and ensure it works as expected."), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "ps ax | grep cron\n")), "\n", React.createElement(_components.h4, null, "Check Cronjobs work"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "sudo crontab -e\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "* * * * * /bin/echo \"cron works\" >> /tmp/file 2>>/tmp/error\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  })), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
